const todos = [
    { id: 1, title: 'Learn React', completed: false },
    { id: 2, title: 'Build a Todo App', completed: false },
  ];
  
  export const getTodos = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve([...todos]);
      }, 500);
    });
  
  export const addTodo = (todo) =>
    new Promise((resolve) => {
      setTimeout(() => {
        todos.push(todo);
        resolve(todo);
      }, 500);
    });
  
  export const updateTodo = (id, updatedTodo) =>
    new Promise((resolve) => {
      setTimeout(() => {
        const index = todos.findIndex((todo) => todo.id === id);
        todos[index] = { ...todos[index], ...updatedTodo };
        resolve(todos[index]);
      }, 500);
    });
  
  export const deleteTodo = (id) =>
    new Promise((resolve) => {
      setTimeout(() => {
        const index = todos.findIndex((todo) => todo.id === id);
        todos.splice(index, 1);
        resolve(id);
      }, 500);
    });