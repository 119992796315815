import React from 'react';

const TodoItem = ({ todo, onDelete, onUpdate }) => {
  const handleCompleteChange = () => {
    onUpdate(todo.id, { completed: !todo.completed });
  };

  return (
    <li>
      <span style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}>
        {todo.title}
      </span>
      <button onClick={() => onDelete(todo.id)}>Delete</button>
      <button onClick={handleCompleteChange}>
        {todo.completed ? 'Undo' : 'Complete'}
      </button>
    </li>
  );
};

export default TodoItem;