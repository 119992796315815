import React, { useState, useEffect } from 'react';
import { getTodos, deleteTodo, updateTodo } from '../api/mockApi';
import TodoItem from './TodoItem';
import TodoForm from './TodoForm';

const TodoList = () => {
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    getTodos().then((data) => setTodos(data));
  }, []);

  const handleDelete = (id) => {
    deleteTodo(id).then(() => {
      setTodos(todos.filter((todo) => todo.id !== id));
    });
  };

  const handleUpdate = (id, updatedTodo) => {
    updateTodo(id, updatedTodo).then((updated) => {
      setTodos(todos.map((todo) => (todo.id === id ? updated : todo)));
    });
  };

  const handleAdd = (newTodo) => {
    setTodos([...todos, newTodo]);
  };

  return (
    <div>
      <h1>Todo List</h1>
      <TodoForm onAdd={handleAdd} />
      <ul>
        {todos.map((todo) => (
          <TodoItem
            key={todo.id}
            todo={todo}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />
        ))}
      </ul>
    </div>
  );
};

export default TodoList;